.button-wrapper {
    margin-top: 20px;
}

.text-green {
    color: #5ea75e !important;
}

.btn:hover {
    color: initial !important;
}

.bold {
    font-weight: 600;
}

.btn-edit {
    cursor: pointer;
}

.text-bold {
    font-weight: 600;
}

.cart-image {
    width: 40px;
    height: 40px;
    margin-right: 15px;
}

.cartItems {
    max-height: 250px;
    overflow-y: scroll;
    scrollbar-width: thin; /* FF */
}

.cartItems::-webkit-scrollbar {
    width: 4px !important;
    height: 10px !important;
}
.cartItems::-webkit-scrollbar-track {
    background: transparent;
}

.cart-item {
    display: flex;
    margin-bottom: 10px;
    padding: 0 1rem;
}

.cart-modal .modal-content {
    min-height: 450px;
}

.cart-item .right-column {
    margin-left: auto;
    /* padding-right: 1rem; */
}

.cart-item .item-count {
    color: #8EC332;
}

.cart-item .item-info {
    display: flex;
    width: 100%;
}

.modal-footer form {
    width: 100%;
}

.modal-footer .popup.btn,
.modal-footer form .popup.btn {
    background-color: #FF5D5E !important;
    border-color: #FF5D5E !important;
}

.comments-field {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.product-window .comments-field {
    padding: 0;
    margin-bottom: 20px !important;
}
.product-window .comments-field button {
    padding: initial;
    background: var(--green);
}

.modal-body {
    padding-top: 1rem !important;
}

.comments-field p {
    padding-left: 1rem;
}

.order-comments {
    width: calc(100% - 2rem);
    margin: 0 1rem;
}

.btn-vieworder {
    background-color: #FF5D5E;
    display: flex;
    justify-content: space-between;
    border-radius: 12px;
    height: 44px;
    flex-direction: row;
    align-items: center;
    max-width: 466px;
    cursor: pointer;
}

.rating-count {
    font-family: "Outfit-Medium";
    font-size: 12px;
    color: #182243;
    margin-left: 5px;
}

.checkout-sum {
    background-color: #fff;
    /* color: #FF5D5E; */
    color: #182243;
    border-radius: 12px;
    padding: 1px 10px;
}

.modal-footer .popup.btn:focus {
    box-shadow: none !important;
}

.modal-body .image-wrapper {
    /* display: flex; */
    display: block;
    justify-content: center;
}

.modal-main-image {
    /* width: 100%; */
    max-width: 200px;
    margin: 0 auto;
    display: block !important;
}

.modal-header {
    position: relative;
    align-items: center;
}

.icon-close {
    position: absolute;
    right: 20px;
    font-size: 23px;
    cursor: pointer;
    top: -1px;
}

.homepage-tags {
    max-width: 736px;
    margin: 20px auto;
    text-align: left;
}

.tag {
    cursor: pointer;
    border-radius: 12px;
    color: #858585;
}

.arrow-block {
    position: absolute;
    top: 2px;
    width: 15px;
    height: 31px;
    /* background-color: #F5F5F5; */
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
}

.arrow-left {
    left: -20px;
    display: none;
}
.arrow-right {
    right: -15px;
}

.tags-container {
    width: 100%;
    overflow-x: visible; 
    white-space: nowrap; 
    /* scrollbar-width: thin; */
    scrollbar-width: none; /* FF */
    position: relative;
    /* padding-left: 20px;
    padding-right: 22px; */
}

.tags-container .tag {
    border-radius: 12px;
    margin-right: 14px;
}

.tags::-webkit-scrollbar, .tags-container::-webkit-scrollbar {
    display: none !important; /* chrome */
    width: 1px !important;
    height: 10px !important;
}

.tags::-webkit-scrollbar-track, .tags-container::-webkit-scrollbar-track {
    background-color: #f1f1f1;
}
  
.tags::-webkit-scrollbar-thumb, .tags-container::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 3px;
}

.tags::-webkit-scrollbar-thumb:hover, .tags-container::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}

.tags {
    display: flex;
    padding: 0;
    margin: 0;
    list-style: none;
    overflow-x: scroll;
    scrollbar-width: none;
    align-items: center;
}

span[data-tag="Favorites"] {
    height: 36px;
}

.tags span {
    display: inline-block;
    padding: 5px 10px;
    background-color: #F5F5F5;
    margin-right: 5px;
    font-family: "Gilroy";
    font-size: 16px;
}

.tag-selected {
    background-color: #FF5D5E !important;
    color: #FFFFFF !important;
}

.slick-dots {
    position: relative !important;
    bottom: 0px !important;
}

.before_description {
    justify-content: space-between;
    display: flex;
    position: relative;
}

.modal-add-to-cart {
    border-radius: 12px;
}
.modal-add-to-cart,
.modal-add-to-cart:hover {
    background-color: #FF5D5E;
    border: none;
    color: #fff !important;
}

.slick-prev::before {
    color: #000000 !important;
}
.slick-next::before {
    color: #000000 !important;
}

.popup.btn {
    height: 44px !important;
}

.button-wrapper .button, .btn.btn-vieworder {
    height: 44px !important;
}

.image-skeleton {
    width: 74px;
    height: 74px;
    background-color: #ccc;
}

.maker-skeleton {
    width: 100px;
    height: 15px;
    background-color: #ccc;
}

.title-skeleton {
    width: 200px;
    height: 15px;
    background-color: #ccc;
}

.price-skeleton {
    width: 70px;
    height: 15px;
    background-color: #ccc;
}

.buttons-skeleton {
    width: 200px;
    height: 15px;
    background-color: #ccc;
}

.tag.skeleton {
    width: 50px;
    height: 34px;
    margin-left: 5px
}

.app_tooltip {
    position: absolute;
    width: 80%;
    top: -54px;
    background-color: #FF5D5E;
    border-radius: 12px;
    padding: 5px;
    color: #fff;
    z-index: 3;
}

.modal-body .app_tooltip {
    top: -20px;
    width: 48%;
}

@media screen and (max-width: 700px) {
    .app_tooltip {
        left: 35%;
    }

    .modal-body .app_tooltip {
        top: -47px;
        width: 63%;
    }
}
@media screen and (min-width: 700px) {
    .app_tooltip {
        left: 50%;
    }
}

.skeleton {
    animation: blinker 2s infinite;
}

@keyframes blinker {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }
    
    100% {
        opacity: 0;
    }
  }

@media screen and (min-width: 335px) and (max-width: 375px) { 
    .homepage-tags.wrapper {
        width: 93vw;
    }

    .cafe-item:nth-child(even) {
        margin-left: 2.5px;
    }
    .cafe-item:nth-child(odd) {
        margin-right: 2.5px;
    }
}

@media screen and (max-width: 500px) {
    .tags-container {
        max-width: 96%;
    }
}

@media screen and (min-width: 375px) and (max-width: 470px) { 
    .homepage-tags.wrapper {
        width: 90vw;
    }

    .tags-container, .searchbar {
        margin-left: auto;
        margin-right: auto;
        width: 95%;
    }
}

@media screen and (min-width: 470px) and (max-width: 480px) { 
    .homepage-tags.wrapper {
        width: 86vw;
    }
}

@media screen and (min-width: 480px) and (max-width: 510px) { 
    .homepage-tags.wrapper {
        width: 83vw;
    }
}

@media screen and (min-width: 510px) and (max-width: 600px) { 
    .homepage-tags.wrapper {
        width: 95vw;
    }
}

@media screen and (min-width: 600px) and (max-width: 660px) { 
    .homepage-tags.wrapper {
        width: 93vw;
    }
}

@media screen and (min-width: 660px) and (max-width: 739px) { 
    .homepage-tags.wrapper {
        width: 91vw;
    }
}

@media screen and (min-width: 739px) { 
    .homepage-tags.wrapper {
        width: 89vw !important;
    }
}

@media screen and (min-width: 740px) { 
    .homepage-tags.wrapper {
        width: 96vw !important;
    }
}

@media screen and (min-width: 715px) and (max-width: 715) { 
    .homepage-tags.wrapper {
        width: 91vw;
    }
}

@media screen and (min-width: 500px) and (max-width: 576px) {
    .viewCart {
        /* left: 3vw;
        width: 93vw; */
    }
    .btn-vieworder, .button-wrapper {
        /* max-width: unset; */
    }
}

.button-wrapper {
    max-width: 380px;
}

@media screen and (max-width: 405px){
    .button-wrapper {
        margin: 0 25px;
    }
}
@media screen and (min-width: 576px){
    .btn-vieworder, .button-wrapper {
        /* max-width: 466px !important; */
        max-width: 466px !important;
    }

    .viewCart {
        position: fixed;
        bottom: 10px;
        left: -1vw;
        width: 100vw;
        z-index: 3;
    }
}