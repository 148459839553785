.button-wrapper {
    margin: 0 auto;
    max-width: 480px;
}

.btn {
    background: #5ea75e;
    color: #fff;
    width: 100%;
    display: block;
    padding: 8px;
    text-decoration: none;
    font-weight: 600;
    text-transform: uppercase;
    border-radius: 5px;
}

.button {
    width: 100%;
}