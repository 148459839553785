.non-empty .cafe-item-decr-button {
    pointer-events: all;
}

.non-empty .js-item-counter {
    transform: scale3d(1, 1, 1) !important;
    color: #000;
    width: 205px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
}

.non-empty .cafe-item-incr-button {
    width: 38px !important;
}

.non-empty .cafe-item-decr-button {
    visibility: visible;
}