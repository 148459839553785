.product-description {
    font-family: "Gilroy" !important;
    font-size: 14px;
    color: #6A6A6B;
}

.nutrition-info p,
.modal-add-to-cart {
    font-family: "Gilroy" !important;
}

.out-of-stock {
    background-color: rgba(255, 93, 94, 0.12) !important;
    color: #FF5D5E !important;
    font-size: 12px;
    border-radius: 6px !important;
    display: flex;
    align-items: center;
    justify-content: center;;
}

.modal-main-image {
    max-width: 100%;
}

.modal-header {
    border-bottom: none !important;
    background-color: #F7F7F7;
}

.modal-content .modal-body {
    padding-top: 0 !important;
}

.popup.btn {
    display: flex;
    height: 51px;
    font-family: "Gilroy-Medium";
    font-size: 16px;
    text-transform: none;
    justify-content: center;
    align-items: center;
    line-height: initial !important;
}

.in-stock {
    background-color: rgba(50, 192, 89, 0.12);
    padding: 2px 10px;
    color: #32C059;
    border-radius: 5px;
    font-size: 12px;
    border-radius: 6px !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.brand {
    font-weight: 500;
    font-family: "Outfit-Medium";
    font-size: 14px;
    color: #182243;
}

.modal-body .comments-field p {
    padding: 0;
}

.modal-body .comments-field .order-comments {
    margin: 0 !important;
    width: 100%;
    margin-bottom: 23px !important;
}

.modal-body .comments-field {
    margin: 0 !important;
}

.divider {
    background-color: #F7F7F7;
    height: 25px;
    position: relative;
}

.divided {
    position: absolute;
    bottom: 0;
    background-color: #fff;
    height: 22px;
    width: 100%;
    border-radius: 85px 85px 0px 0px;
}

.modal-title.h4 {
    width: 100%;
    text-align: center;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.share {
    /* position: absolute; */
    right: 0;
    top: 0;
    width: 30px;
    cursor: pointer;
}

.product-title {
    font-weight: 500;
    font-size: 20px;
    width: 68%;
    color: #182243;
    font-family: "Outfit-Semibold";
}

.title-info {
    display: flex;
    justify-content: space-between;
}

.cafe-item-price {
    font-size: 14px;
}

.btn-pay {
    height: 44px;
    border-radius: 12px;
    /* max-width: 380px; */
    margin: auto;
    color: #fff;
    font-family: "Gilroy-Semibold" !important;
}

.btn-vieworder:hover .btn-vieworder-text {
    color: #182243;
}

.modal-footer {
    border-top: none !important;
    padding: 1rem 0.6rem;
}

.cart-window {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.cart-window .modal-footer {
    width: 100%;
    margin: auto;
}

/* .modal-dialog {
    height: 100%;
}

.modal-content {
    height: 80%;
} */

.comments-field {
    padding: 0 1rem;
}

.old-price {
    font-size: 12px;
    text-decoration: line-through;
    color: #79797A;
    vertical-align: text-top;
    margin-left: 5px;
}

.nutrition-table {
    background-color: #F7F7F7;
    border-radius: 5px;
    font-size: 14px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

.nutition-table-title {
    font-family: "Outfit-Semibold";
}
.nutrition-header {
    font-family: "Gilroy-Medium";
    height: 39px;
    padding: 0 8px;
    align-items: center;
    background-color: #FF5D5E;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    color: #fff;
    display: flex;
    justify-content: space-between;
    padding: 8px;
}

.nutrition-info {
    padding: 8px;
    color: #6A6A6B;
}

.extra-info {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    margin-bottom: 20px;
}

.nutrition-info p {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.price {
    color: #FF5D5E;
    font-size: 20px;
    font-family: "Outfit-Semibold";
}

.description-title {
    font-size: 16px;
    color: #182243;
    font-family: "Outfit-Semibold";
}

.weight {
    font-family: "Outfit";
    font-size: 16px;
    color: #79797A;
    padding-bottom: 5px;
    font-size: initial;
    font-weight: initial;
    color: initial;
}

.modal-body {
    padding: 0 !important;
}

.modal-body .before_description,
.modal-body header,
.modal-body main,
.modal-body .modal-footer {
    padding: 1rem;
}

.modal-body header {
    padding: 0px 1rem;
}

.modal-body .before_description {
    padding-bottom: 12px;
    padding-top: 14px;
}

.modal-body .image-wrapper {
    background-color: #F7F7F7;
}

span.tag {
    background-color: #eeeeee;
    color: #79797A;
    font-size: 12px;
    font-family: "Outfit";
    padding: 5px 12px;
    border-radius: 6px;
    margin-right: 10px;
    display: inline-block;
    margin-bottom: 5px;
}

.modal-body .tag {
    height: 25px;
    font-size: 12px;
    margin-right: 8px;
}

.price-block {
    text-align: right;
    font-size: 20px;
}

.price-block .old-price {
    font-size: 16px;
    font-family: "Outfit";
    color: #79797A;
    margin-right: 8px;
}

.tags {
    margin-bottom: 20px;
    margin-top: 10px;
}

.value-column {
    font-weight: 600;
    color: #182243;
}

.slick-dots li button::before {
    color: #E1E0E0 !important;
}
.slick-dots li.slick-active button::before {
    color: #FF5D5E !important;
}

.store-name {
    margin: auto;
    font-weight: 600;
}

/*MEDIA QUERIES*/
@media screen and (min-width: 800px) {
    .share {
        /* display: none; */
    }
}