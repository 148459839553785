@font-face {
  font-family: "Outfit";
  src: url("./assets/fonts/Outfit/Outfit-Regular.ttf");
}
@font-face {
  font-family: "Outfit-Medium";
  src: url("./assets/fonts/Outfit/Outfit-Medium.ttf");
}
@font-face {
  font-family: "Outfit-Bold";
  src: url("./assets/fonts/Outfit/Outfit-Bold.ttf");
}
@font-face {
  font-family: "Outfit-Semibold";
  src: url("./assets/fonts/Outfit/Outfit-SemiBold.ttf");
}
@font-face {
  font-family: "Outfit-Light";
  src: url("./assets/fonts/Outfit/Outfit-Light.ttf");
}
@font-face {
  font-family: "Gilroy";
  src: url("./assets/fonts/Gilroy/Gilroy-Regular.otf");
}
@font-face {
  font-family: "Gilroy-Medium";
  src: url("./assets/fonts/Gilroy/Gilroy-Medium.otf");
}
@font-face {
  font-family: "Gilroy-Semibold";
  src: url("./assets/fonts/Gilroy/Gilroy-SemiBold.otf");
}

.App {
  text-align: center;
}

.product-rating {
  display: flex;
  align-items: center;
  justify-content: end;
}

.header-store-name {
  text-align: center;
  font-family: "Gilroy-Semibold";
  color: #182243;
  font-size: 18px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.like {
  cursor: pointer;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.login-form {
  display: flex;
  flex-direction: column;
}

.login-popup {
  display: none;
  width: 50%;
  position: absolute;
  padding: 25px;
  top: 30%;
  left: 30%;
  background: #fff;
  z-index: 2;
}

.header-nav {
  justify-content: end;
  display: flex;
}

.header-nav a {
  margin-right: 10px;
}

.login-popup.visible {
  display: block;
}

/*Search bar*/
.searchbar,
.ais-SearchBox {
  width: 100%;
  background-color: #F5F5F5;
  padding: 14px;
  color: #858585;
  font-family: "Gilroy";
  height: 48px;
  border-radius: 10px;
  margin-bottom: 10px;
  margin-top: 10px;
  background-image: url("./assets/ui/search-normal.svg");
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: 20px center;
  overflow: hidden;
}

.ais-SearchBox-form input {
  padding-top: 0;
}

.ais-SearchBox-submitIcon,
.ais-SearchBox-form {
  display: none;
}

.center {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.searchbar input,
.ais-SearchBox-form input {
  width: 100%;
  border: none;
  margin-left: 30px;
  outline: none;
  background: transparent;
}
/*end search bar*/

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.login-form input {
  width: 100%;
  background-color: #F5F5F5;
  padding: 14px;
  color: #858585;
  font-family: "Gilroy";
  height: 48px;
  border-radius: 10px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.login-form button {
  width: 100%;
  height: 40px;
  margin-top: 10px;
  padding: 0px 0px;
  border-radius: 6px;
  background-color: var(--green);
}